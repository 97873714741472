import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "welcome-to-the-party",
      "style": {
        "position": "relative"
      }
    }}>{`Welcome to the Party!`}<a parentName="h2" {...{
        "href": "#welcome-to-the-party",
        "aria-label": "welcome to the party permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <h4 {...{
      "id": "collect-first-party-website-analytics-and-transfer-everything-to-your-warehouse",
      "style": {
        "position": "relative"
      }
    }}>{`Collect first-party website analytics, and transfer everything to your warehouse`}<a parentName="h4" {...{
        "href": "#collect-first-party-website-analytics-and-transfer-everything-to-your-warehouse",
        "aria-label": "collect first party website analytics and transfer everything to your warehouse permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h4>
    <p>{`Firstparty makes it easy to collect hit-level analytical events from your website by collecting Events triggered by
a pageview, a form completion, or just about anything else. Events, Sessions, and Profiles may then all be exported to
any number of data warehouses of your choosing.`}</p>
    <h4 {...{
      "id": "automate-business-processes-in-response-to-collected-events",
      "style": {
        "position": "relative"
      }
    }}>{`Automate business processes in response to collected Events`}<a parentName="h4" {...{
        "href": "#automate-business-processes-in-response-to-collected-events",
        "aria-label": "automate business processes in response to collected events permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h4>
    <p>{`Events may be manipulated to change or adjust the data sent from the client (browser), or trigger additional work with
external platforms, though a system of Rules. In addition to Events, Firstparty will keep track of web sessions
and customer Profiles.`}</p>
    <h4 {...{
      "id": "manage-a-single-audience-definition-and-send-it-to-multiple-third-party-platforms",
      "style": {
        "position": "relative"
      }
    }}>{`Manage a single Audience definition and send it to multiple third-party platforms`}<a parentName="h4" {...{
        "href": "#manage-a-single-audience-definition-and-send-it-to-multiple-third-party-platforms",
        "aria-label": "manage a single audience definition and send it to multiple third party platforms permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h4>
    <p>{`Events, Sessions, and Profiles may be combined to build Audiences representing individuals who have interacted with
your business. These Audiences may then be sent to external platforms, including ad networks and email marketing
software, so you can engage with your target customers without sending their PII to third-parties.`}</p>
    <h2 {...{
      "id": "the-firstparty-data-lifecycle",
      "style": {
        "position": "relative"
      }
    }}>{`The Firstparty Data Lifecycle`}<a parentName="h2" {...{
        "href": "#the-firstparty-data-lifecycle",
        "aria-label": "the firstparty data lifecycle permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Firstparty's data lifecycle is designed to allow flexibility and customization. Events are sent to Firstparty from a
specified Source, may trigger Rules, and then are sent to configured Destinations.`}</p>
    <h3 {...{
      "id": "sources",
      "style": {
        "position": "relative"
      }
    }}>{`Sources`}<a parentName="h3" {...{
        "href": "#sources",
        "aria-label": "sources permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`Sources define how Firstparty ingests Events. Firstparty will collect Events from the web browser through Javascript you
install on your site, via an HTTP API, from a configured database or warehouse, or several other source types. `}<br /><a className="mt-3 text-decoration-none" href="/docs/sources/overview/">{`Learn More About Sources`}<i className="fe fe-arrow-right ms-1"></i></a></p>
    <h3 {...{
      "id": "events",
      "style": {
        "position": "relative"
      }
    }}>{`Events`}<a parentName="h3" {...{
        "href": "#events",
        "aria-label": "events permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`Data sent to Firstparty is received as a series of Events. Each Event has an Event Type (things like "Page Viewed" or
"Form Completed"), a Key that defines the Source the Event is sent from, timestamps, and Properties that may be set
when the Event is sent. `}<br /><a className="mt-3 text-decoration-none" href="/docs/items/events/">{`Learn More About Events`}<i className="fe fe-arrow-right ms-1"></i></a></p>
    <h3 {...{
      "id": "rules",
      "style": {
        "position": "relative"
      }
    }}>{`Rules`}<a parentName="h3" {...{
        "href": "#rules",
        "aria-label": "rules permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`When an Event is received, any Rules associated with the Event's specified Source are evaluated. If the Rule's Conditions
evaluate as true, the Rule's Actions are executed. `}<br /><a className="mt-3 text-decoration-none" href="/docs/rules/overview/">{`Learn More About Rules`}<i className="fe fe-arrow-right ms-1"></i></a></p>
    <h3 {...{
      "id": "destinations",
      "style": {
        "position": "relative"
      }
    }}>{`Destinations`}<a parentName="h3" {...{
        "href": "#destinations",
        "aria-label": "destinations permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`Data stored in Firstparty can be sent to any number of Destinations you define. Destinations may include Warehouses
where the individual Events will be stored for use later, or Ad Networks and other external systems where Audiences
may be sent for marketing purposes. `}<br /><a className="mt-3 text-decoration-none" href="/docs/destinations/overview/">{`Learn More About Destinations`}<i className="fe fe-arrow-right ms-1"></i></a></p>
    <h2 {...{
      "id": "first-steps-with-firstparty",
      "style": {
        "position": "relative"
      }
    }}>{`First Steps with Firstparty`}<a parentName="h2" {...{
        "href": "#first-steps-with-firstparty",
        "aria-label": "first steps with firstparty permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`There are many paths to success with Firstparty. We recommend choosing a Workflow that includes setting up a JavaScript
Source on your website, since that Source is often a requirement in additional Workflows. `}<br /><a className="mt-3 text-decoration-none" href="/docs/workflows/overview/">{`Learn About Workflows`}<i className="fe fe-arrow-right ms-1"></i></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      